import * as React from "react";
import {useEffect, useState} from "react";
import {translate} from "../../translate/translate";
import {Box, Fab, Grid, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import {BaseService} from "../../services/base-service";
import {Search} from "@mui/icons-material";
import {URLS} from "../../services/app-urls";
import {Field, Form, Formik} from "formik";
import AutocompletePaginated from "../../components/autocomplete/autocompletePaginated";
import {ErrorComponent} from "../../components/error/error.component";
import {ReasonReplanning} from "../../models/basic/reason-replanning";
import * as Yup from "yup";
import * as echarts from "echarts/core";
import {DataZoomComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";

import EChartsReact, {useChart,} from "echarts-for-react-fc";
import {toast} from "react-toastify";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const ReasonReplanningReportComponent = () => {
    // Services
    const service = new BaseService<ReasonReplanning>(URLS.REASON_REPLANNING);

    // Hooks
    const [reasonReplanning, setReasonReplanning] = useState<string>("");
    const [initial_date, setInitialDate] = useState<string>("");
    const [final_date, setFinalDate] = useState<string>("");
    const [showChart, setShowChart] = useState<boolean>(false);
    const {chartRef, setChartOption, handleListenChartReady} = useChart();

    const emphasisStyle = {
        itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0,0,0)"
        }
    };

    // Form
    const schema = Yup.object().shape({
        reason_replanning: Yup.number(),
        initial_date: Yup.string().required(),
        final_date: Yup.string().required(),
    });

    // Use effects
    useEffect(() => {
        setChartOption({
            grid: {},
            tooltip: {},
            legend: {},
            toolbox: {},
            dataZoom: [],
            xAxis: {},
            yAxis: {},
            series: []
        });
    });

    // Handle and get functions

    const handleReasonReplanningChange = (event: any) => {
        setShowChart(false);
        if (event) {
            setReasonReplanning(event.id);
        } else {
            setReasonReplanning("");
        }
    };

    const handleInitialDateChange = (date: any) => {
        setShowChart(false);
        setInitialDate(date);
    };

    const handleFinalDateChange = (date: any) => {
        setShowChart(false);
        setFinalDate(date);
    };

    const handleClickSetLineOption = (data: any) => {
        if (data.length > 0) {
            let legends = new Map<string, string>();
            let series: any = [];
            let labels: any = [];

            data.forEach((day: any) => {
                labels.push(String(day.day) + "/" + String(day.month) + "/" + String(day.year));
                day.reasons.forEach((reason: any) => {
                    if (reason.reason_replanning != null) {
                        legends.set(reason.reason_replanning, reason.reason_replanning);
                    }
                });
            });
            legends.forEach((value, key) => {
                const series_item = {
                    name: value,
                    type: "bar",
                    stack: "1",
                    data: [],
                    emphasis: emphasisStyle,
                    barCategoryGap: "15%",
                    label: {show: true, position: "inside"}
                };
                data.forEach((item: any) => {
                    if (item.reasons.find((s: any) => s.reason_replanning === key)) {
                        const dataValue = Number(item.reasons.find((s: any) => s.reason_replanning === key).quantity);
                        series_item.data.push(dataValue as never);
                    } else {
                        series_item.data.push(0 as never);
                    }
                });
                series.push(series_item);
            });

            setShowChart(true);
            setChartOption(
                {
                    xAxis: {
                        data: labels,
                        type: "category",
                        nameLocation: "end",
                        name: translate.t("date"),
                        splitArea: {show: true},
                        axisTick: {
                            show: false,
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            show: true,
                            rotate: 45,
                        },
                    },
                    yAxis: {
                        type: "value",
                        interval: 1,
                        name: translate.t("replanning_amount"),
                        nameLocation: "middle",
                        nameTextStyle: {
                            align: "center",
                            padding: [10, 10, 10, 10]
                        },
                    },
                    dataZoom: [{
                        type: "slider",
                        show: true,
                        realtime: true,
                        start: 0,
                        end: 100,
                    }],
                    toolbox: {
                        show: true,
                        itemSize: 18,
                        feature: {
                            saveAsImage: {
                                name: translate.t("reason-replanning-report"),
                                title: translate.t("save")
                            }
                        }
                    },
                    grid: {
                        right: "5%",
                        left: "5%",
                        containLabel: true
                    },
                    tooltip: {},
                    legend: {
                        type: "scroll",
                        data: Array.from(legends.values()),
                        top: "1.5%"
                    },
                    series: series,
                },
                {
                    notMerge: true,
                }
            );
            legends = new Map<string, string>();
            series = [];
            labels = [];
        } else {
            toast.info(translate.t("empty_listing"));
        }

    };

    const getReportData = () => {
        service.clearParameters();
        if (reasonReplanning) {
            service.addParameter("reason", reasonReplanning);
        }
        service.addParameter("initial_date", initial_date);
        service.addParameter("final_date", final_date);
        service.getFromListRoute("report_chart_replanning_reason")
            .then(response => {
                handleClickSetLineOption(response.data);
            }).catch((error: any) => {ErrorComponent(error);});

    };

    // Render return
    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("replanning_reason")}
                </Typography>
            </div>

            <Grid item xs>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            initialValues={{reasonReplanning: "", initial_date: "", final_date: ""}}
                            validationSchema={schema}
                            validateOnChange={true}
                            validateOnMount={true}
                            enableReinitialize
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    getReportData();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                ({values, touched}) => {
                                    return (
                                        <Form autoComplete="off">
                                            <Grid alignItems="center" justifyContent={"flex-start"} container spacing={2} direction={"row"}>
                                                <Grid item xs>
                                                    <Field
                                                        as={TextField}
                                                        id="id_initial_date"
                                                        name="initial_date"
                                                        label={translate.t("initial_date")}
                                                        required={true}
                                                        value={initial_date}
                                                        values={values}
                                                        onChange={(event: any) => handleInitialDateChange(event.target.value as string)}
                                                        type="date"
                                                        fullWidth
                                                        error={(touched.initial_date && !initial_date) || (final_date < initial_date)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            max: final_date,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {touched.initial_date && !initial_date && (
                                                                        <div className={"required-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    )}
                                                                    {touched.initial_date && initial_date > final_date && (
                                                                        <div className={"error-field"}>
                                                                            {translate.t("must_be_less_equal_than_final_date")}
                                                                        </div>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <Field
                                                        as={TextField}
                                                        id="id_final_date"
                                                        name="final_date"
                                                        label={translate.t("final_date")}
                                                        required={true}
                                                        value={final_date}
                                                        values={values}
                                                        onChange={(event: any) => handleFinalDateChange(event.target.value as string)}
                                                        type="date"
                                                        fullWidth
                                                        error={(touched.final_date && !final_date) || (final_date < initial_date)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            min: initial_date,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {touched.final_date && !final_date && (
                                                                        <div className={"error-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    )}
                                                                    {touched.final_date && final_date < initial_date && (
                                                                        <div className={"error-field"}>
                                                                            {translate.t("must_be_bigger_equal_than_start_date")}
                                                                        </div>
                                                                    )}
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <AutocompletePaginated
                                                        display="name"
                                                        searchField="name"
                                                        label="reason"
                                                        service={service}
                                                        filters={[{"active": true}]}
                                                        onSelectElement={handleReasonReplanningChange}
                                                        required={false}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Fab
                                                        size="small"
                                                        color="secondary"
                                                        disabled={!initial_date || !final_date || initial_date > final_date}
                                                        onClick={getReportData}>
                                                        <Search/>
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                        </Formik>
                    </Box>
                </Paper>

                {
                    showChart && (
                        <Paper sx={{marginTop: "16px"}}>
                            <Grid item>
                                <div id={"chart"}>
                                    <EChartsReact
                                        autoResize={true}
                                        style={{
                                            width: "100%",
                                            height: 500,
                                        }}
                                        ref={chartRef}
                                        echarts={echarts}
                                        onChartReady={handleListenChartReady}
                                    />
                                </div>

                            </Grid>
                        </Paper>
                    )
                }
            </Grid>


        </section>
    );
};

export default ReasonReplanningReportComponent;
