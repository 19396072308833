import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Fab, Grid, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import {Search} from "@mui/icons-material";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import * as echarts from "echarts/core";
import {DataZoomComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {translate} from "../../../translate/translate";
import {ErrorComponent} from "../../../components/error/error.component";
import {DowntimeReason} from "../../../models/basic/downtime-reason";
import DowntimeByQuantityReportComponent from "./by-available-time-report/by-available-time-report.component";
import {toast} from "react-toastify";
import {DowntimeReportData} from "../../../models/downtime-report-data";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const DowntimeSummaryByAvailableTimeReportComponent = () => {
    // Services
    const service = new BaseService<DowntimeReportData>(URLS.DOWNTIME_REPORT_DATA);
    const downtime_reason_service = new BaseService<DowntimeReason>(URLS.DOWNTIME_REASON);

    // Hooks
    const [selectedDowntimeReasons] = useState<any[]>([]);
    const [start_date, setStartDate] = useState<string>("");
    const [end_date, setEndDate] = useState<string>("");
    const [showYearlyChart, setShowYearlyChart] = useState<boolean>(false);
    const [showMonthlyChart, setShowMonthlyChart] = useState<boolean>(false);
    const [showDailyChart, setShowDailyChart] = useState<boolean>(false);
    const [showWeeklyChart, setShowWeeklyChart] = useState<boolean>(false);
    const [reasons, setReasons] = useState<DowntimeReason[]>([]);
    const [reportData, setReportData] = useState<any>("");

    // Form
    const schema = Yup.object().shape({
        downtime_reasons: Yup.array(),
        start_date: Yup.string().required(),
        final_date: Yup.string().required(),
    });

    // Handle and get functions
    const getReasons = () => {
        downtime_reason_service.clearParameters();
        downtime_reason_service.addParameter("ordering", "-id");
        downtime_reason_service.addParameter("active", true);
        downtime_reason_service.getAll()
            .then((response: any) => {
                setReasons(response.data);
            });
    };

    // Use effects
    useEffect(() => {
        getReasons();
    }, []);

    const handleInitialDateChange = (date: any) => {
        setShowYearlyChart(false);
        setShowMonthlyChart(false);
        setShowWeeklyChart(false);
        setShowDailyChart(false);
        setStartDate(date);
    };

    const handleFinalDateChange = (date: any) => {
        setShowYearlyChart(false);
        setShowMonthlyChart(false);
        setShowWeeklyChart(false);
        setShowDailyChart(false);
        setEndDate(date);
    };

    const getReportData = () => {
        setShowYearlyChart(false);
        setShowMonthlyChart(false);
        setShowWeeklyChart(false);
        setShowDailyChart(false);

        service.clearParameters();
        if (selectedDowntimeReasons) {
            service.addParameter("reasons_id", selectedDowntimeReasons);
        }
        service.addParameter("start_date", start_date);
        service.addParameter("end_date", end_date);
        service.getFromListRoute("report_chart_downtime_summary_by_available_time")
            .then(response => {
                if (response.data) {
                    setReportData(response.data);
                    setShowYearlyChart(true);
                    setShowMonthlyChart(true);
                    setShowWeeklyChart(true);
                    setShowDailyChart(true);
                } else {
                    toast.warning(translate.t("empty_listing"));
                }
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    // Render return
    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("downtime_summary_by_available_time_report")}
                </Typography>
            </div>

            <Grid item xs>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            initialValues={{start_date: "", end_date: ""}}
                            validationSchema={schema}
                            validateOnChange={true}
                            validateOnMount={true}
                            enableReinitialize
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    getReportData();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                ({values, touched}) => {
                                    return (
                                        <Form autoComplete="off">
                                            <Grid alignItems="center" justifyContent={"flex-start"} container spacing={2} direction={"row"}>
                                                <Grid item xs alignItems="center" justifyContent={"flex-start"} container spacing={2} direction={"row"}>
                                                    <Grid item xs={2}>
                                                        <Field
                                                            as={TextField}
                                                            id="id_start_date"
                                                            name="start_date"
                                                            label={translate.t("start_date")}
                                                            required={true}
                                                            value={start_date}
                                                            values={values}
                                                            onChange={(event: any) => handleInitialDateChange(event.target.value as string)}
                                                            type="date"
                                                            fullWidth
                                                            error={(touched.start_date && !start_date)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                max: end_date,
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {touched.start_date && !start_date && (
                                                                            <div className={"required-field"}>
                                                                                {translate.t("required_field")}
                                                                            </div>
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Field
                                                            as={TextField}
                                                            id="id_end_date"
                                                            name="end_date"
                                                            label={translate.t("end_date")}
                                                            required={true}
                                                            value={end_date}
                                                            values={values}
                                                            onChange={(event: any) => handleFinalDateChange(event.target.value as string)}
                                                            type="date"
                                                            fullWidth
                                                            error={(touched.end_date && !end_date) || (touched.end_date && start_date && end_date < start_date)}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                min: start_date,
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {touched.end_date && !end_date && (
                                                                            <div className={"error-field"}>
                                                                                {translate.t("required_field")}
                                                                            </div>
                                                                        )}
                                                                        {touched.end_date && end_date < start_date && (
                                                                            <div className={"error-field"}>
                                                                                {translate.t("must_be_bigger_equal_than_start_date")}
                                                                            </div>
                                                                        )}
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Fab
                                                        size="small"
                                                        color="secondary"
                                                        disabled={!start_date || !end_date || start_date > end_date}
                                                        onClick={getReportData}>
                                                        <Search/>
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                        </Formik>
                    </Box>
                </Paper>

                {showYearlyChart && (
                    <DowntimeByQuantityReportComponent reportData={reportData.by_year} chartTitle={translate.t("downtime_by_year")}></DowntimeByQuantityReportComponent>
                )}

                {showMonthlyChart && (
                    <DowntimeByQuantityReportComponent reportData={reportData.by_month} chartTitle={translate.t("downtime_by_month")}></DowntimeByQuantityReportComponent>
                )}

                {showWeeklyChart && (
                    <DowntimeByQuantityReportComponent reportData={reportData.by_week} chartTitle={translate.t("downtime_by_week")}></DowntimeByQuantityReportComponent>
                )}

                {showDailyChart && (
                    <DowntimeByQuantityReportComponent reportData={reportData.by_date} chartTitle={translate.t("downtime_by_day")}></DowntimeByQuantityReportComponent>
                )}

            </Grid>
        </section>
    );
};

export default DowntimeSummaryByAvailableTimeReportComponent;
