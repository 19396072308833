import "../planning-by-delivery.component.css";
import {Box, DialogActions, DialogTitle, Grid, Typography, Dialog, DialogContent} from "@mui/material";
import {translate} from "../../../../translate/translate";
import * as React from "react";
import {useEffect} from "react";
import {Product} from "../../../../models/basic/product";
import {Assignment, Close} from "@mui/icons-material";
import {ErrorComponent} from "../../../../components/error/error.component";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {DateNowEnUS} from "../../../../components/date/date-timeFormat";
import {Delivery} from "../../../../models/planning/delivery";
import Button from "@mui/material/Button";


class ColumnDto {
    column: string;
    values: [];

    constructor() {
        this.column = "";
        this.values = [];
    }
}

class ResultDto {
    days: string[];
    columns: ColumnDto[];

    constructor() {
        this.days = [];
        this.columns = [];
    }
}

const DeliveryDetailComponent = (props: any) => {
    const {open, onClose, product, startDate} = props;
    const service = new BaseService<Delivery>(URLS.DELIVERY);
    const [dataSource, setDataSource] = React.useState<ResultDto>(new ResultDto());
    const productService = new BaseService<Product>(URLS.PRODUCT);
    const [productObject, setProductObject] = React.useState(new Product());

    const getProduct = () => {
        productService.clearParameters();
        productService.getById(product).then((response) => {
            setProductObject(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const search = () => {
        service.clearParameters();
        service.addParameter("product", product);
        const end = new Date(startDate);
        end.setDate(end.getDate() + 29);
        service.addParameter("start_date", startDate);
        service.addParameter("end_date", DateNowEnUS(end));
        service.getFromListRoute("get_product_delivery_values").then((response: any) => {
            setDataSource(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    useEffect(() => {
        getProduct();
    }, [product]);

    useEffect(() => {
        search();
    }, []);

    const formatDate = (date: string) => {
        return date.split("-")[2].padStart(2, "0") + "/" + date.split("-")[1].padStart(2, "0") + "/" + date.split("-")[0];
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div>
            <Dialog open={open} onClose={() => handleClose()} fullWidth={true} maxWidth={"xl"}>
                <DialogTitle sx={{backgroundColor: "primary.main", padding: "16px"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px" display="flex" alignItems="center">
                                {translate.t("product")}: {productObject.model} - {productObject.name}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}} alignItems="center" display="flex">
                            <Close onClick={() => handleClose()}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers tabIndex={0}>
                    <div style={{height: "500px"}}>
                        {dataSource && (<div className="mat-repeat-container">
                            <div className="mat-repeat-scroller">
                                <Box position="sticky" top="0" zIndex={3}>
                                    <Box width="auto" display="flex" flexDirection="row">
                                        <Box position="sticky" left="0" display="flex" flexDirection="column" className="product">
                                            <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                            </Box>
                                        </Box>
                                        {dataSource.days.map((day, index) => {
                                            return (
                                                <Box key={index} display="flex" flexDirection="column" className="header-date">
                                                    <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                                        <Typography variant="body2">{formatDate(day)}</Typography>
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                                {dataSource.columns.map((item, index) => {
                                    return (
                                        <Box key={index}>
                                            <Box display="flex" flexDirection="row">
                                                <Box position="sticky" zIndex={2} left="0" display="flex" flexDirection="column" className="product">
                                                    <Box
                                                        style={{backgroundColor: "#f5f5f5", paddingLeft: "8px", paddingRight: "8px"}}
                                                        display="flex" flex="1" justifyContent="left" alignItems="center" overflow="hidden">
                                                        <Typography variant="body2" fontWeight="bold">{translate.t(item.column)}</Typography>
                                                    </Box>
                                                </Box>
                                                {item.values.map((value, index) => {
                                                    return (
                                                        <Box key={index} display="flex" flexDirection="column" className="line-date">
                                                            <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                                                <Typography variant="body2">{value}</Typography>
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </div>
                        </div>)}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button-options"
                        color="secondary"
                        onClick={() => handleClose()}
                        variant="outlined">
                        {translate.t("cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeliveryDetailComponent;
