export const URLS = {
    // auth
    USER: "/api/account/user/",
    GROUP: "/api/account/group/",
    MENU: "/api/account/menu/",
    MODULE: "/api/account/module/",
    MODULE_MENU: "/api/account/module_menu/",
    TOKEN: "/api/account/token/",
    CONTENT_TYPE: "/api/account/content_type/",
    PERMISSION: "/api/account/permission/",

    //registration
    STATION: "/api/pplan/station/",
    SEGMENT: "/api/pplan/segment/",
    SCHEDULED_STOP: "/api/pplan/scheduled_stop/",
    MARKER: "/api/pplan/marker/",
    REASON_REPLANNING: "/api/pplan/reason_replanning/",
    CALENDAR_EVENT_TYPE: "/api/pplan/calendar_event_type/",
    CALENDAR_EVENT: "/api/pplan/calendar_event/",
    PRODUCTION_LINE: "/api/pplan/production_line/",
    PRODUCTION_LINE_STATION: "/api/pplan/production_line_station/",
    PRODUCT: "/api/pplan/product/",
    SIDE: "/api/pplan/side/",
    SHIFT: "/api/pplan/shift/",
    SCHEDULED_STOP_SHIFT: "/api/pplan/scheduled_stop_shift/",
    PRODUCTION_RAMP: "/api/pplan/production_ramp/",
    PRODUCTION_RAMP_SHIFT: "/api/pplan/production_ramp_shift/",
    ALERT: "/api/pplan/alert/",
    DOWNTIME_REASON: "/api/pplan/downtime_reason/",
    DOWNTIME: "/api/pplan/downtime/",
    DOWNTIME_REPORT_DATA: "/api/pplan/downtime_report_data/",
    OEE: "/api/pplan/oee/",
    OEE_MONTH_GOAL: "/api/pplan/oee_month_goal/",
    OEE_YEAR_GOAL: "/api/pplan/oee_year_goal/",

    //planning
    DEMAND: "/api/pplan/demand/",
    VERSION: "/api/pplan/version/",
    PRODUCTION_ORDER_DEMAND: "/api/pplan/production_order_demand/",
    DAILY_PRODUCTION_PERIPHERAL: "/api/pplan/daily_production_peripheral/",
    PLANNING: "/api/pplan/worker_planning/",
    DAILY_PLANNING: "/api/pplan/daily_planning/",
    GROUP_PLANNING: "/api/pplan/group_planning/",
    SPREADSHEET_IMPORT: "/api/pplan/spreadsheet_import/",
    SPREADSHEET_IMPORT_ITEM: "/api/pplan/spreadsheet_import_item/",
    PRODUCT_STOCK: "/api/pplan/product_stock/",
    DELIVERY: "/api/pplan/delivery/",
    SIMULATION: "/api/pplan/simulation/",
    DAILY_PRODUCTION: "/api/pplan/daily_production/",

    // production_capacity
    SMT_TIME: "/api/pplan/smt_time/",
    SMT_TIME_LINE: "/api/pplan/smt_time_line/",
    SMT_DEFAULT_TIME_: "/api/pplan/smt_default_time/",
    MANUAL_DEFAULT_TIME: "/api/pplan/manual_default_time/",

    // settings
    HEADCOUNT: "/api/pplan/headcount/",
    EXPRESSION_BUILDER: "/api/pplan/expression_builder/",
    SETTING: "/api/pplan/settings/",

    // dashboard
    LINE_SCHEDULE: "/api/pplan/line_schedule/",
    LINE_SCHEDULE_MODEL: "/api/pplan/line_schedule_model/",
    LINE_SCHEDULE_STATION: "/api/pplan/line_schedule_station/",
};
