import "./realized-and-planned.css";
import * as React from "react";
import {useEffect, useState} from "react";
import * as echarts from "echarts/core";
import {translate} from "../../../translate/translate";
import {Box, Card, CardContent, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, TextField, Typography} from "@mui/material";
import {BaseService} from "../../../services/base-service";
import {Search} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {Field, Form, Formik} from "formik";
import {Demand} from "../../../models/basic/demand";
import {ErrorComponent} from "../../../components/error/error.component";
import {Product} from "../../../models/basic/product";
import Select from "@mui/material/Select";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import {GridComponent, LegendComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import moment from "moment";
import RealizedAndPlannedChartComponent from "./realized-and-planned-chart/realized-and-planned-chart";
import {toast} from "react-toastify";


echarts.use([GridComponent, BarChart, CanvasRenderer, TooltipComponent, LegendComponent]);

const RealizedAndPlannedComponent = () => {
    moment.locale(translate.language == "pt" ? "pt-br" : "en-us", {week: {dow: 1}});

    const service = new BaseService<Demand>(URLS.GROUP_PLANNING);
    const productService = new BaseService<Product>(URLS.PRODUCT);

    const startDateOfWeek = moment().subtract(7, "day").format("YYYY-MM-DD");
    const endDateOfWeek = moment().format("YYYY-MM-DD");

    const [start_date, setStartDate] = useState(startDateOfWeek);
    const [end_date, setEndDate] = useState(endDateOfWeek);
    const [type, setType] = React.useState<string>("S");
    const [product, setProduct] = useState<Product>();
    const [reportData, setReportData] = useState<any>("");
    const [showDailyChart, setShowDailyChart] = useState<boolean>(false);
    const [showTable, setShowTable] = useState<boolean>(false);

    const [currentWeekPercent, setCurrentWeekPercent] = useState<string>("0%");
    const [currentMonthPercent, setCurrentMonthPercent] = useState<string>("0%");
    const [currentYearPercent, setCurrentYearPercent] = useState<string>("0%");
    const [currentYearBacklogPercent, setCurrentYearBacklogPercent] = useState<string>("0%");

    const [lastWeeksPercents, setLastWeeksPercents] = useState<any>();

    const handleStartDateChange = (event: any) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event: any) => {
        setEndDate(event.target.value);
    };

    const hideCharts = () => {
        setShowDailyChart(false);
        setShowTable(false);
    };

    const showCharts = () => {
        setShowDailyChart(true);
        setShowTable(true);
    };

    useEffect(() => {
        getRealizedVersusPlannedWeekPercents();
        getRealizedVersusPlannedPercents();
    }, []);

    // Get percents
    const getRealizedVersusPlannedPercents = () => {
        service.clearParameters();
        service.addParameter("production_line_type", type);
        if (product) {
            service.addParameter("product_id", product.id);
        }
        service.getFromListRoute("realized_versus_planned_percents")
            .then((response: any) => {
                setCurrentWeekPercent(response.data.current_week_percent);
                setCurrentMonthPercent(response.data.current_month_percent);
                setCurrentYearPercent(response.data.current_year_percent);
                setCurrentYearBacklogPercent(response.data.current_year_backlog_percent);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    // Get week percents
    const getRealizedVersusPlannedWeekPercents = () => {
        service.clearParameters();
        service.addParameter("production_line_type", type);
        if (product) {
            service.addParameter("product_id", product.id);
        }
        service.getFromListRoute("realized_vs_planned__last_week_percents")
            .then((response: any) => {
                setLastWeeksPercents(response.data);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    // Search
    const search = () => {
        hideCharts();
        service.clearParameters();
        service.addParameter("initial_date", start_date);
        service.addParameter("final_date", end_date);
        service.addParameter("production_line_type", type);
        if (product) {
            service.addParameter("product_id", product.id);
        }
        service.getFromListRoute("realized_versus_planned_report")
            .then((response: any) => {
                if (response.data) {
                    setReportData(response.data);
                    showCharts();
                } else {
                    toast.warning(translate.t("empty_listing"));
                }
                getRealizedVersusPlannedPercents();
                getRealizedVersusPlannedWeekPercents();
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    // Render return
    return (
        <Box>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("realized_vs_planned")}
                </Typography>
            </div>
            <Grid>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            initialValues={{type: "S"}}
                            enableReinitialize={true}
                            validateOnMount={true}
                            onSubmit={(values: any, {setSubmitting}) => {
                                setTimeout(() => {
                                    search();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                ({values, errors, touched}) => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs sm md lg>
                                                <Field
                                                    as={TextField}
                                                    id="id_initial_date"
                                                    name="initial_date"
                                                    label={translate.t("initial_date")}
                                                    values={values}
                                                    value={start_date}
                                                    autoFocus={false}
                                                    type="date"
                                                    fullWidth
                                                    required={true}
                                                    onChange={handleStartDateChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={touched.initial_date && Boolean(errors.initial_date)}
                                                />
                                            </Grid>
                                            <Grid item xs sm md lg>
                                                <Field
                                                    as={TextField}
                                                    id="id_end_date"
                                                    name="end_date"
                                                    label={translate.t("final_date")}
                                                    values={values}
                                                    value={end_date}
                                                    autoFocus={false}
                                                    type="date"
                                                    fullWidth
                                                    required={true}
                                                    onChange={handleEndDateChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={touched.end_date && Boolean(errors.end_date)}
                                                />
                                            </Grid>
                                            <Grid item xs sm md lg>
                                                <FormControl fullWidth>
                                                    <InputLabel className={"formControl"} htmlFor="type">
                                                        {translate.t("type")} *
                                                    </InputLabel>
                                                    <Field
                                                        variant={"outlined"}
                                                        defaultValue="S"
                                                        label={translate.t("type")}
                                                        name="type"
                                                        component={Select}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setType(event.target.value as string)}
                                                    >
                                                        <MenuItem value="S">{translate.t("SMT")}</MenuItem>
                                                        <MenuItem value="M">{translate.t("Manual")}</MenuItem>
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs sm md lg>
                                                <AutocompletePaginated
                                                    display={["model","name"]}
                                                    searchField="full_description"
                                                    label="product"
                                                    service={productService}
                                                    onSelectElement={(product: Product) => {
                                                        setProduct(product);
                                                    }}
                                                    autoFocus={true}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    disabled={!start_date || !end_date || !type}
                                                    color="secondary">
                                                    <Search/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Grid marginTop={2} display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center">
                <Card>
                    <CardContent style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <Typography variant="h5" component="div" alignContent="center">{currentWeekPercent}%</Typography>
                        <Typography color="text.secondary">{translate.t("current_week_realized")}</Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <Typography variant="h5" component="div">{currentMonthPercent}%</Typography>
                        <Typography color="text.secondary">{translate.t("current_month_realized")}</Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <Typography variant="h5" component="div">{currentYearPercent}%</Typography>
                        <Typography color="text.secondary">{translate.t("current_year_realized")}</Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <Typography variant="h5" component="div">{currentYearBacklogPercent}%</Typography>
                        <Typography color="text.secondary">{translate.t("current_year_backlog")}</Typography>
                    </CardContent>
                </Card>
            </Grid>

            <Grid marginTop={2} display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center">
                <Box width="auto" display="flex" flexDirection="row">

                    <Box position="sticky" left="0" display="flex" flexDirection="column" className="value-container">
                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="line-value">
                            <Typography variant="body2" color="white">{translate.t("week")}</Typography>
                        </Box>
                    </Box>

                    {lastWeeksPercents && lastWeeksPercents.week.map((week: string, index: number) => {
                        return (
                            <Box key={index} display="flex" flexDirection="column" className="line-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                    <Typography variant="body2">{week}</Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
                <Box width="auto" display="flex" flexDirection="row">
                    <Box position="sticky" left="0" display="flex" flexDirection="column" className="value-container">
                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="line-value">
                            <Typography variant="body2" color="white">{translate.t("percentage")}</Typography>
                        </Box>
                    </Box>

                    {lastWeeksPercents && lastWeeksPercents.percent.map((percent: string, index: number) => {
                        return (
                            <Box key={index} display="flex" flexDirection="column" className="line-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                    <Typography variant="body2">{percent}%</Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Grid>

            {showDailyChart && (<Grid marginTop={2}>
                <RealizedAndPlannedChartComponent chartTitle={translate.t("realized_and_planned_by_day")} reportData={reportData.by_day}></RealizedAndPlannedChartComponent>
            </Grid>)}

            {showTable && (
                <Grid marginTop={2}>
                    <Box width="auto" display="flex" flexDirection="row">
                        <Box position="sticky" left="0" display="flex" flexDirection="column" className="value-container">
                            <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="line-value">
                                <Typography variant="body2" color="white">{translate.t("month")}</Typography>
                            </Box>
                        </Box>

                        {reportData.by_month.daily_planning_date.map((month: string, index: number) => {
                            return (
                                <Box key={index} display="flex" flexDirection="column" className="line-date">
                                    <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                        <Typography variant="body2">{month}</Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    <Box width="auto" display="flex" flexDirection="row">
                        <Box position="sticky" left="0" display="flex" flexDirection="column" className="value-container">
                            <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="line-value">
                                <Typography variant="body2" color="white">{translate.t("planned")}</Typography>
                            </Box>
                        </Box>

                        {reportData.by_month.total_planned.map((planned: number, index: number) => {
                            return (
                                <Box key={index} display="flex" flexDirection="column" className="line-date">
                                    <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                        <Typography variant="body2">{planned}</Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    <Box width="auto" display="flex" flexDirection="row">
                        <Box position="sticky" left="0" display="flex" flexDirection="column" className="value-container">
                            <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="line-value">
                                <Typography variant="body2" color="white">{translate.t("realized")}</Typography>
                            </Box>
                        </Box>

                        {reportData.by_month.total_realized.map((realized: number, index: number) => {
                            return (
                                <Box key={index} display="flex" flexDirection="column" className="line-date">
                                    <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                        <Typography variant="body2">{realized}</Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    <Box width="auto" display="flex" flexDirection="row">
                        <Box position="sticky" left="0" display="flex" flexDirection="column" className="value-container">
                            <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="line-value">
                                <Typography variant="body2" color="white">{translate.t("percentage")}</Typography>
                            </Box>
                        </Box>

                        {reportData.by_month.percent.map((percent: number, index: number) => {
                            return (
                                <Box key={index} display="flex" flexDirection="column" className="line-date">
                                    <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                        <Typography variant="body2">{percent}%</Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    <Box width="auto" display="flex" flexDirection="row">
                        <Box position="sticky" left="0" display="flex" flexDirection="column" className="value-container">
                            <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="line-value">
                                <Typography variant="body2" color="white">{translate.t("backlog")}</Typography>
                            </Box>
                        </Box>

                        {reportData.by_month.backlog.map((backlog: number, index: number) => {
                            return (
                                <Box key={index} display="flex" flexDirection="column" className="line-date">
                                    <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                        <Typography variant="body2">{backlog}</Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>

                </Grid>
            )}
        </Box>
    );
};

export default RealizedAndPlannedComponent;
