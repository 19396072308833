import * as React from "react";
import {useEffect, useState} from "react";
import {translate} from "../../../translate/translate";
import {Box, Button, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, TextField, Typography} from "@mui/material";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import {BaseService} from "../../../services/base-service";
import {Add, Search} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {PaginatedResult} from "../../../models/default/paginated-result";
import TableActionComponent from "../../../components/table/table-action.component";
import {Field, Form, Formik} from "formik";
import HistoryComponent from "../../../components/history/history.component";
import Select from "@mui/material/Select";
import {ErrorComponent} from "../../../components/error/error.component";
import {Downtime} from "../../../models/basic/downtime";
import {ProductionLine} from "../../../models/basic/production-line";
import {DowntimeReason} from "../../../models/basic/downtime-reason";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";


const DowntimeComponent = () => {
    // URL REQUESTS
    const service = new BaseService<Downtime>(URLS.DOWNTIME);
    const url = "/registration/downtime/";
    const navigate = useNavigate();

    // SERVICES
    const productionLineService = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const downtimeReasonService = new BaseService<DowntimeReason>(URLS.DOWNTIME_REASON);

    // HOOKS
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [idHistory, setIdHistory] = React.useState(0);
    const [searchType, setSearchType] = React.useState<string>("");
    const [pagination, setPagination] = React.useState<PaginatedResult<Downtime>>();
    const [dataSource, setDataSource] = React.useState<Downtime[]>([]);
    const [searchProductionLine, setSearchProductionLine] = useState<ProductionLine>();
    const [searchDowntimeReason, setSearchDowntimeReason] = useState<DowntimeReason>();
    const [searchStartDate, setSearchStartDate] = useState("");
    const [searchEndDate, setSearchEndDate] = useState("");

    const handleOpenDialogHistory = (id: number) => {
        setIdHistory(id);
        setOpenHistory(true);
    };

    const handleCloseDialogHistory = () => {
        setOpenHistory(false);
    };

    const handleStartDateChange = (event: any) => {
        setSearchStartDate(event.target.value);
    };

    const handleEndDateChange = (event: any) => {
        setSearchEndDate(event.target.value);
    };

    const search = (restartIndex = false) => {
        service.clearParameters();
        if (searchType) {
            service.addParameter("type", searchType);
        }
        if (searchProductionLine) {
            service.addParameter("production_line_id", searchProductionLine.id);
        }
        if (searchDowntimeReason) {
            service.addParameter("reason_id", searchDowntimeReason.id);
        }
        if (searchStartDate) {
            service.addParameter("start_date", searchStartDate);
        }
        if (searchEndDate) {
            service.addParameter("end_date", searchEndDate);
        }
        service.addParameter("expand", "production_line,reason");
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        service.getAllPaginate().then(response => {
            setPagination(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
        restartIndex && setPage(0);
    };

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDoubleClick = (row: Downtime) => {
        navigate(`${url}${row.id}`);
    };

    const handleDelete = (row: Downtime) => {
        service.delete(Number(row.id)).then(() => {
            toast.success(translate.t("successfully_deleted"));
            service.getAll().then(() => {
                search();
            });
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleSwitch = (row: Downtime, column: keyof Downtime) => {
        (row[column] as boolean) = !row[column];
        const payload = {"active": row.active};
        service.patch(payload, Number(row.id)).then(() => {
            toast.success(translate.t("successfully_updated"));
            service.clearParameters();
            search();
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("downtime")}
                </Typography>
            </div>
            <div className="floating-button">
                <Button
                    component={NavLink}
                    to={url + "create"}
                    startIcon={<Add/>}
                    variant="contained"
                    color="secondary"
                    className={"button-new"}>
                    {translate.t("new")}
                </Button>
            </div>

            <Grid item xs={12}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{name: ""}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    search(true);
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {() => (
                                <Form autoComplete="off">
                                    <Grid alignItems="center" container spacing={2} direction={"row"}>
                                        <Grid item xs sm md lg>
                                            <AutocompletePaginated
                                                display="name"
                                                searchField="name"
                                                label="production_line"
                                                service={productionLineService}
                                                onSelectElement={(productionLine: ProductionLine) => {
                                                    setSearchProductionLine(productionLine);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs sm md lg>
                                            <AutocompletePaginated
                                                display="description"
                                                searchField="description"
                                                label="downtime_reason"
                                                service={downtimeReasonService}
                                                onSelectElement={(downtime: DowntimeReason) => {
                                                    setSearchDowntimeReason(downtime);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs sm md lg>
                                            <FormControl fullWidth>
                                                <InputLabel className={"formControl"} htmlFor="status">
                                                    {translate.t("type")}
                                                </InputLabel>
                                                <Field
                                                    variant={"outlined"}
                                                    defaultValue=""
                                                    label={translate.t("type")}
                                                    name="type"
                                                    component={Select}
                                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                        setSearchType(event.target.value as string)}
                                                >
                                                    <MenuItem value="">-</MenuItem>
                                                    <MenuItem value="S">{translate.t("scheduled")}</MenuItem>
                                                    <MenuItem value="U">{translate.t("unscheduled")}</MenuItem>
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs sm md lg>
                                            <Field
                                                as={TextField}
                                                id="id_initial_date"
                                                name="initial_date"
                                                label={translate.t("initial_date")}
                                                autoFocus={false}
                                                type="date"
                                                fullWidth
                                                onChange={handleStartDateChange}
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item xs sm md lg>
                                            <Field
                                                as={TextField}
                                                id="id_end_date"
                                                name="end_date"
                                                label={translate.t("end_date")}
                                                autoFocus={false}
                                                type="date"
                                                fullWidth
                                                onChange={handleEndDateChange}
                                                InputLabelProps={{shrink: true,}}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                type="submit"
                                                color="secondary">
                                                <Search/>
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Paper variant="outlined" sx={{marginTop: "16px"}}>
                <TablePaginationComponent
                    field={"type"}
                    service={service}
                    state={dataSource}
                    displayedColumnsShow={["#", "production_line", "reason", "type", "start_date", "end_date", "action"]}
                    displayedColumns={["id", "production_line.name", "reason.description", "type", "start_date", "end_date", "action"]}
                    dateColumns={["start_date", "end_date"]}
                    handleDelete={handleDelete}
                    handleSwitch={handleSwitch}
                    url={url}
                    handleDoubleClick={handleDoubleClick}
                    count={pagination?.count}
                    next={pagination?.next}
                    previous={pagination?.previous}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    actionsComponent={TableActionComponent}
                    handleOpenDialogHistory={handleOpenDialogHistory}
                    columnStyles={{1: "5%", 2: "15%", 3: "10%", 4: "10%", 5: "10%", 6: "10%", 7: "15%"}}
                />
            </Paper>
            <HistoryComponent
                openHistory={openHistory}
                handleCloseDialogHistory={handleCloseDialogHistory}
                idHistory={idHistory}
                columns_history={["id", "production_line.name", "reason.description", "type", "start_date", "end_date"]}
                service={service}
                dateColumns={["start_date", "end_date"]}
            />
        </section>
    );
};

export default DowntimeComponent;
