import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {translate} from "../../../../translate/translate";
import {FormControlLabel, Grid, Switch, TextField, Typography} from "@mui/material";
import {Field} from "formik";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {PlanningCalendar} from "../../../../dto/planning-calendar";
import {toast} from "react-toastify";
import {Close} from "@mui/icons-material";
import {ErrorComponent} from "../../../../components/error/error.component";

const DialogVersion = (props: any) => {
    const {
        open,
        onClose,
        version
    } = props;
    const [description, setDescription] = React.useState("");
    const [simulation, setSimulation] = React.useState(false);
    const service = new BaseService<PlanningCalendar>(URLS.PLANNING);
    const handleDescription = (event: any) => {
        setDescription(event.target.value);
    };

    const handleClose = (result: boolean) => {
        if (result) {
            duplicateVersion();
            setDescription("");
        } else {
            setDescription("");
            onClose();
        }

    };

    const handleChangeSimulation = (newValue: boolean) => {
        setSimulation(newValue);
    };

    const duplicateVersion = () => {
        const data = {
            version: version.url,
            new_version_name: description,
            simulation_version: simulation,
            empty_version: false
        };
        service.clearParameters();
        service.postFromListRoute(data, "duplicate_version").then((response) => {
            toast.success(translate.t("duplicate_version_successfully"));
            onClose(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    return (
        <Dialog open={open} onClose={() => handleClose(false)} fullWidth>
            <Grid padding={2} container justifyContent="space-between" sx={{backgroundColor: "primary.main"}}>
                <Grid item>
                    <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                        {translate.t("duplicate_version")}
                    </Typography>
                </Grid>
                <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                    <Close onClick={() => handleClose(false)}/>
                </Grid>
            </Grid>
            <DialogContent >

                <Grid display="flex" flexDirection="row" alignItems="center" container spacing={1} sx={{height: "150px", marginTop: "20px"}}>
                    <Grid item xs>
                        <Field
                            as={TextField}
                            name="description"
                            label={translate.t("description")}
                            variant={"outlined"}
                            fullWidth
                            autoFocus={true}
                            required={true}
                            onChange={handleDescription}
                            inputProps={{maxLength: 64}}/>
                    </Grid>
                    <Grid item container direction={"row"}>
                        <FormControlLabel
                            control={
                                <Field
                                    type="checkbox"
                                    name="simulation"
                                    component={Switch}
                                    checked={simulation}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChangeSimulation(e.target.checked)}
                                    color="secondary"
                                />
                            }
                            label={translate.t("simulation")}
                        />
                    </Grid>
                    <Grid item container direction={"row"}>
                        <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                            <Grid item>
                                <Button
                                    className="button-options"
                                    color="secondary"
                                    onClick={() => handleClose(false)}
                                    variant="outlined">
                                    {translate.t("cancel")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className="button-options"
                                    color="success"
                                    onClick={() => handleClose(true)}
                                    disabled={!description}
                                    variant="contained">
                                    {translate.t("save")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DialogVersion;
